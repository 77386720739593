import React from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
// components
import Layout from "../components/layout"
import Heading1 from "../components/atoms/heading_1"
import SEO from "../components/seo"

const StyledNotFound = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  grid-column-end: -2;
  grid-column-start: 2;
  height: calc(100vh - 100px);
  justify-content: center;
  padding: 25px 0;

  @media (max-width: 767px) {
    height: calc(100vh - 68px);
    padding: 10px 0 25px;
  }
`

const Success = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return (
    <Layout>
      <StyledNotFound>
        <Heading1
          fontSize={
            isMobile
              ? "large"
              : "x-large"
          }
          heading="お問い合わせ、またはご応募ありがとうございました。"
        />
      </StyledNotFound>
    </Layout>
  )
}

export default Success

export const Head = ({ location }) => (
  <SEO
    title="お問い合わせ、またはご応募ありがとうございました"
    pathname={location.pathname}
  />
)
